import { render, staticRenderFns } from "./tabs-details-view.vue?vue&type=template&id=417875c1&scoped=true&"
import script from "./tabs-details-view.vue?vue&type=script&lang=js&"
export * from "./tabs-details-view.vue?vue&type=script&lang=js&"
import style0 from "./tabs-details-view.vue?vue&type=style&index=0&id=417875c1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "417875c1",
  null
  
)

export default component.exports