<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import BasicTable from "@/components/listings/basic-table";
// import { badgeColor, statusLabel } from "@/util/mapfunction.js";
import StatusBadge from "@/components/buttons/tenancy-status";

export default {
  components: {
    BasicTable,
    StatusBadge
  },
  data(){
    return {
      tenancyFields: [
        { key: "order_display_id", label:'ID' },
        { key: "status",  label:'Status' },
        { key: "created_at", label:'Request Date' },
      ],
      perPage: 50,
      tenancyItem: [],
      totalRecords: 0,
      current_page: 1,
      tenancy_loading: false
    }
  },
  props: ['id'],
  async mounted() {
    this.loadData(this.current_page);
  },
  methods: {
    async changePage(pg) {
      await this.loadData(pg);
    },
    async loadData(page) {
      this.tenancy_loading = true;
      const result = await axios.get(`${process.env.VUE_APP_APIURL}/tenancy/from-member/${this.id}`, {
        headers: authHeader(),
        params: {
          "type": "tenancy",
          "length": this.perPage,
          "page": page
        }
      }).catch( function (error) {
        alert("Load error:" + error.message);
      });

      if (result.data.code === 'success') {
        const {data} = result.data.data.data;
        this.tenancyItem = [];
        data.forEach(element => {
          let item = {
            "order_id": element.tenancy.order_id, 
            "order_display_id": element.tenancy.order_display_id,
            "status": element.tenancy.status,
            "created_at": element.tenancy.created_at == null ? '-' : element.tenancy.created_at,
            // "payment": "-",
            // "details": "-",
          }
          this.tenancyItem.push(item);
          this.totalRecords = result.data.data.total_records;
        });
      }
      this.tenancy_loading = false;
    },
    // badgeClass(val) {
    //   return badgeColor("tenancy", val);
    // },
    // badgeText(val) {
    //   return statusLabel(val);
    // },
  }
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <BasicTable
        :perPage="perPage"
        :fields="tenancyFields"
        :items="tenancyItem"
        :isBusy="tenancy_loading"
        :totalRows="totalRecords"
        @page-click="changePage"
      >
        <template v-slot:cell(order_display_id)="row">
          <router-link :to="{ name: 'Tenancy View New', params: { id: row.item.order_id} }" 
            class="font-size-12"
          >
            {{ row.item.order_display_id }}
          </router-link>
        </template> 

        <template v-slot:cell(status)="row">
          <StatusBadge :status="row.value" type="tenancy" v-if="row.value != ''"/>
          <!-- <div
            class="badge font-size-12"
            :class="badgeClass(row.value)"
          >
            {{ badgeText(row.value) }}
          </div> -->
        </template> 
      </BasicTable>
    </div>
  </div>
</template>