<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import TabsDetails from "@/views/pages/member/tabs-details-view";
import TabsTenancy from "@/views/pages/member/tabs-tenancy-view";
// import TabsPoints from "@/views/pages/member/tabs-point-view";
// import axios from "axios";
// import { authHeader } from "../../../helpers/auth-header";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ConfirmationModal,
    MessageModal,
    TabsDetails,
    TabsTenancy,
    // TabsPoints
  },
  async created() {
  },
  data() {
    const id = this.$route.params.id;
    const modeName = id === "add" ? "Add" : "Edit";

    return {
      id: id,
      title: `Member View`,
      modeName,
      items: [
        {
          text: "Member",
        },
        {
          text: "view",
          active: true,
        },
      ],
    };
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    async save() {},
    
    async handleFileUpload() {
      // this.attachmentFile =
      // console.log(this.$refs.file.files[0]);
      // console.log(authHeader());
      // if (this.$refs.file.files[0] != undefined) {
      //   let formData = new FormData();
      //   formData.append('attachment', this.$refs.file.files[0]);
      //   const result = await axios.post(`${process.env.VUE_APP_APIURL}/member/${this.id}/attachment`,
      //   formData,
      //   {
      //       headers: {
      //           'Content-Type': 'multipart/form-data',
      //           "Authorization": authHeader()['Authorization']
      //       }
      //   });
      // console.log(result);
      // }
    },
    PopUpMessage (msg) {
      this.$refs.messageModal.showModal(msg);
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <ConfirmationModal ref="confirmationModal" />
    <MessageModal ref="messageModal"/>
    
    <b-tabs content-class="" active>
      <!-- tab 1 -->
      <b-tab title="Details">
        <TabsDetails :id="id" @shoWPopup="PopUpMessage($event)"/>
      </b-tab>

      <!-- tab 2 -->
      <!-- <b-tab title="Points">
        <TabsPoints :id="this.id" lazy/>
      </b-tab> -->

      <!-- tab 3 -->
      <b-tab title="Tenancies" lazy>
        <TabsTenancy :id="id"/>
      </b-tab>
    </b-tabs>
  </Layout>
</template>
