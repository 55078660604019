<template>
  <table class="table">
    <thead>
      <tr class="tableHeader">
        <th colspan="2" class="table_title">{{ tableHeaderText }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="Object.keys(tableData).length === 0">
        <td>No records found</td>
      </tr>
      <tr v-else v-for="(data, index) in tableData" :key="index">
        <th class="text-capitalize">{{ data.label }}</th>
        <td style="white-space:pre-wrap;">
          <slot :name="index" v-bind="data">
            <span style="width: calc(100% - 25px); display: inline-block !important;">
              {{ data.value ?? '-' }}
              <div class="alert alert-danger" role="alert" v-if="data.hasOwnProperty('error')" style="padding: 5px !important;">
                {{ data.error[0] }}
              </div>
            </span>
          </slot>
          
          <span style="width: 25px;">
            <i class="ri-close-circle-fill" style="float: right; color: red;" v-show="data.hasOwnProperty('error')"></i>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "Table",
  created() {},
  data() {
    return {
    };
  },
  props: [
    'tableHeaderText',
    'tableData',
  ],
  methods: {
    // handleInput(e) {
    // }
    // watch: {
    //   tableData(val) {
    //     console.log(val);
    //   }
    // }
  },
  watch: { 
    tableData: { 
      // handler(newValue) {
      //   // console.log(newValue, oldValue);
      //   console.log(newValue);
      // },
      // deep: true
    },
    
  }
};
</script>
<style lang="scss" scoped>
  th, td, tr {
    border: 1px solid #dee2e6;
    vertical-align: middle;
  }
  .table_title {
    font-size: 15px;
    font-weight: 600;
  }

  .tableHeader {
    color: #fff;
    background: #9F9F9F;
  }
</style>